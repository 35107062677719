import React, { useEffect } from "react"
import { useAnimation, motion } from "framer-motion"
import ThemeContext from "../context/ThemeContext"
import MoonbirdVideo from "../images/birb01.webm"
import MoonbirdVideoMp4 from "../images/birb01.mp4"

const Moonbird = () => {
  const comeIntoView = useAnimation()

  const variants = {
    visible: {
      y: "10%",
      transition: {
        delay: 0.25,
        type: "spring",
        stiffness: 150,
        duration: 3,
        mass: 0.5,
      },
    },
    hidden: { y: "100%" },
    flickHead: {
      scaleX: -1,
      transition: {
        duration: 0.01,
        delay: 2,
        repeatDelay: 2,
        repeat: Infinity,
        repeatType: "reverse",
      },
    },
  }

  useEffect(() => {
    comeIntoView.start("visible")
  }, [comeIntoView])

  return (
    <ThemeContext.Consumer>
      {theme => (
        <div className="pt-32 lg:pt-16 overflow-hidden relative -z-10 top-px">
          <motion.div
            animate={comeIntoView}
            variants={variants}
            initial="hidden"
          >
            <video
              className="lg:max-w-[35rem] w-full h-auto"
              autoPlay
              muted
              loop
              playsInline
            >
              <source src={MoonbirdVideo} type="video/webm" />
              <source src={MoonbirdVideoMp4} type="video/mp4" />
            </video>
          </motion.div>
        </div>
      )}
    </ThemeContext.Consumer>
  )
}

export default Moonbird
