import React from "react"

const Arrow = props => {
  return (
    <svg
      width="28"
      height="14"
      viewBox="0 0 28 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 0V3.25L26.12 6.63L14 10.02V13.27L27.61 6.63L14 0Z"
        fill="currentColor"
      />
      <path
        d="M23.82 6.63008L0 5.08008V8.18008L23.82 6.63008Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Arrow
