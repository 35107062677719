import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Layout from "../components/Layout"
import Container from "../components/Container"
import Seo from "../components/Seo"
import Arrow from "../components/Arrow"
import Arrow2 from "../components/Arrow2"
import ThemeContext from "../context/ThemeContext"
import MoonBird from "../components/Moonbird"

const ManifestoPage = ({ data }) => {
  return (
    <ThemeContext.Consumer>
      {theme => (
        <Layout>
          <Seo
            title="Ours to own"
            description="DeepTech Ventures is here to close the gap between the promise of Web3 and a reality where users can finally call the internet 'ours to own.'"
          />
          <div className="border-b border-current">
            <Container className="h-full pt-8">
              <div className="flex items-end h-full">
                <div className="grid grid-cols-8 w-full relative position">
                  <div
                    className="col-span-5 pr-[15%] flex items-end"
                    data-sal="slide-up"
                    data-sal-delay={100}
                    data-sal-duration="500"
                    data-sal-easing="ease-out"
                  >
                    <div className="pb-4 md:pb-12">
                      <h1 className="border rounded-full uppercase inline-block tracking-widest px-2 leading-tight text-sm mb-3">
                        Manifesto
                      </h1>
                      <h2 className="text-[9vw] md:text-[7vw] font-light leading-[1]">
                        Venturing
                        <br /> closer to the
                        <br />
                        <span className={`${theme.playful ? "font-mono" : ""}`}>
                          future
                        </span>{" "}
                        of <br />
                        Web3
                      </h2>
                    </div>
                  </div>
                  <div className="col-span-3 flex items-end">
                    {theme.playful && <MoonBird />}
                  </div>

                  <AnchorLink to="#intro">
                    <Arrow className="hidden md:block rotate-90 -translate-y-1/2 absolute bottom-16 left-1/2" />
                  </AnchorLink>
                </div>
              </div>
            </Container>
          </div>
          <Container>
            <div
              className="grid grid-cols-8 pt-12 pb-32 md:pt-32 md:pb-52"
              id="intro"
            >
              <div className="hidden md:block cols-span-2"></div>
              <div className="col-span-8 md:col-span-6">
                <div className="prose font-light md:prose-lg max-w-[42rem]">
                  <h2 className="text-2xl md:text-3xl font-light">
                    DeepTech Ventures is here to close the gap between the
                    promise of Web3 and a reality where users can finally call
                    the internet 'ours to own.'
                  </h2>
                  <p>
                    DeepTech Ventures is here to close the gap between the
                    promise of Web3, the third generation of the internet, and a
                    reality where users can finally call the internet 'ours to
                    own.' The Web2 of today is slowly unravelling. This
                    evolution from a privately owned internet of data hoarding
                    platforms, to a world where people can be in control of
                    their data, identity, and destiny, is a huge opportunity.
                    Entire billion-dollar industries - from finance, gaming,
                    art, and more - are being transformed. As investment
                    pioneers, DeepTech Ventures is giving an intrepid community
                    the resources to become an established industry with the
                    power to change the world. For Web3 to truly come alive, its
                    layered and fragmented infrastructure needs reinforcing and
                    fusing together. Imagine information that can be stored,
                    accessed, and shared between tiers, free from the prying
                    eyes of profiteering platforms. For the architects and
                    builders of the internet of tomorrow this is a playground of
                    infinite programming possibility.
                  </p>
                  <h2 className="text-2xl md:text-3xl font-light mt-0">
                    This is why we dare to invest in the{" "}
                    <span className="font-mono">power</span> of possible. Our
                    approach is twofold.
                  </h2>
                  <p>
                    We support visionaries to build a robust Web3 framework,
                    across the entire tech stack. So that data can flow safely
                    and surely between ecosystem players. From the networks, to
                    the nodes, to the rules governing indexing, sorting,
                    movement and displaying of information. All the way to the
                    software and apps that interpret this raw data, as usable
                    gold that benefits the users who own the data lifeblood of
                    the internet. We also fund specific protocols and apps with
                    the potential to shake up their industry or create entire
                    new markets. In this brave new world, users become
                    tokenholders collectively governing - and reaping the
                    rewards as the internet edges closer to becoming 'ours to
                    own'.
                  </p>

                  <hr />

                  <p>
                    Post investment we actively participate in the collective
                    endeavour of building out Web3: We take part in the
                    governance and securing of networks we invest in so the
                    protocols can become sustainable. We spin up nodes to
                    provide computational power and data storage that
                    contributes toward a truly decentralised web where users
                    also provide the infrastructure that gets rid of data silos.
                    As true believers in the Web3 promise, we also scour the
                    ecosystem and invest our personal money in the spirit of
                    playfulness and experimentation that got blockchain to where
                    it is.
                  </p>

                  <h2 className="text-3xl md:text-6xl font-light !leading-[1] !mt-[1em]">
                    Join us on this journey to make the internet{" "}
                    <span className="font-mono">'ours to own'</span>.
                  </h2>
                </div>
              </div>
            </div>
          </Container>
          <div className="flex justify-end mx-auto max-w-[100rem]">
            <button
              onClick={theme.toggleContact}
              className="text-left font-light w-3/4 md:w-1/2 border border-r-0 border-b-0 text-xl p-4 flex justify-between 2xl:border-r max-w-[32.5rem]"
            >
              <div className="pr-12">
                Helping to make Web3 a reality?
                <br />
                <span className="underline">Get in touch</span>.
              </div>
              <Arrow2 />
            </button>
          </div>
        </Layout>
      )}
    </ThemeContext.Consumer>
  )
}

export default ManifestoPage
