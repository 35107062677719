import React from "react"

const Arrow2 = props => {
  return (
    <svg
      width="112"
      height="112"
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="currentColor">
        <path d="M112 56L0 68.2528V43.7472L112 56Z" />
        <path d="M112 56L37.8447 30.1168V0L112 56Z" />
        <path d="M112 56L37.8447 81.8832V112L112 56Z" />
      </g>
    </svg>
  )
}

export default Arrow2
